import { ChainId, chainToChainId } from "@wormhole-foundation/sdk";
import { ProtocolIcon } from "src/components/atoms";
import { formatAppId } from "src/utils/crypto";
import {
  CCTP_APP_ID,
  // CCTP_MANUAL_APP_ID_STRING, we are putting them from the front in the tx detail
  CONNECT_APP_ID,
  ETH_BRIDGE_APP_ID,
  MAYAN_APP_ID,
  NTT_APP_ID,
  PORTAL_NFT_APP_ID,
  PORTAL_APP_ID,
  GR_APP_ID,
  // UNKNOWN_APP_ID, disabled until the backend is ready
  GATEWAY_APP_ID,
  USDT_TRANSFER_APP_ID,
  TBTC_APP_ID,
  C3_APP_ID,
  OMNISWAP_APP_ID,
  ALL_BRIDGE_APP_ID,
  ALLBRIDGE_URL,
  CCTP_URL,
  MAYAN_URL,
  MAYAN_MCTP_APP_ID,
  MAYAN_SWIFT_APP_ID,
  PORTAL_BRIDGE_URL,
  NTT_URL,
  OMNISWAP_URL,
  C3_URL,
  GATEWAY_URL,
  SWAP_LAYER_APP_ID,
  FAST_TRANSFERS_APP_ID,
  TBTC_URL,
  GR_URL,
  MAYAN_SHUTTLE_APP_ID,
  LIQUIDITY_LAYER_APP_ID,
} from "src/consts";

const appIds = [
  C3_APP_ID,
  CCTP_APP_ID,
  CONNECT_APP_ID,
  ETH_BRIDGE_APP_ID,
  GATEWAY_APP_ID,
  GR_APP_ID,
  MAYAN_APP_ID,
  MAYAN_MCTP_APP_ID,
  MAYAN_SWIFT_APP_ID,
  NTT_APP_ID,
  OMNISWAP_APP_ID,
  PORTAL_APP_ID,
  PORTAL_NFT_APP_ID,
  TBTC_APP_ID,
  USDT_TRANSFER_APP_ID,
  // UNKNOWN_APP_ID, // disabled until the backend is ready
];

export const PROTOCOL_LIST: { label: string; value: string }[] = [
  ...appIds.map(appId => ({
    icon: <ProtocolIcon protocol={appId} />,
    label: formatAppId(appId),
    value: String(appId),
  })),
  {
    icon: <ProtocolIcon protocol={MAYAN_SHUTTLE_APP_ID} />,
    label: "Mayan Shuttle",
    value: SWAP_LAYER_APP_ID,
  },
  {
    icon: <ProtocolIcon protocol={LIQUIDITY_LAYER_APP_ID} />,
    label: "Wormhole Liquidity Layer",
    value: FAST_TRANSFERS_APP_ID,
  },
].sort((a, b) => a.label.localeCompare(b.label));

export const ChainFilterMainnet = [
  chainToChainId("Acala"),
  chainToChainId("Algorand"),
  chainToChainId("Aptos"),
  chainToChainId("Arbitrum"),
  chainToChainId("Aurora"),
  chainToChainId("Avalanche"),
  chainToChainId("Base"),
  chainToChainId("Blast"),
  chainToChainId("Bsc"),
  chainToChainId("Celo"),
  chainToChainId("Ethereum"),
  chainToChainId("Fantom"),
  chainToChainId("Injective"),
  chainToChainId("Karura"),
  chainToChainId("Klaytn"),
  chainToChainId("Mantle"),
  chainToChainId("Moonbeam"),
  chainToChainId("Near"),
  chainToChainId("Neon"),
  chainToChainId("Oasis"),
  chainToChainId("Optimism"),
  chainToChainId("Polygon"),
  chainToChainId("Scroll"),
  chainToChainId("Sei"),
  chainToChainId("Snaxchain"),
  chainToChainId("Solana"),
  chainToChainId("Sui"),
  chainToChainId("Terra"),
  chainToChainId("Terra2"),
  chainToChainId("Wormchain"),
  chainToChainId("Xlayer"),
  chainToChainId("Xpla"),
];

export const ChainFilterTestnet = [
  chainToChainId("Acala"),
  chainToChainId("Celo"),
  chainToChainId("Algorand"),
  chainToChainId("PolygonSepolia"),
  chainToChainId("Aptos"),
  // chainToChainId("Arbitrum"),
  chainToChainId("ArbitrumSepolia"),
  chainToChainId("Aurora"),
  // chainToChainId("Base"),
  chainToChainId("BaseSepolia"),
  chainToChainId("Blast"),
  chainToChainId("Bsc"),
  chainToChainId("Fantom"),
  chainToChainId("Avalanche"),
  chainToChainId("Ethereum"),
  chainToChainId("Holesky"),
  chainToChainId("Injective"),
  chainToChainId("Karura"),
  chainToChainId("Klaytn"),
  chainToChainId("Mantle"),
  chainToChainId("Moonbeam"),
  // chainToChainId("Polygon"),
  chainToChainId("Near"),
  chainToChainId("Neon"),
  chainToChainId("Oasis"),
  // chainToChainId("Optimism"),
  chainToChainId("OptimismSepolia"),
  chainToChainId("Scroll"),
  chainToChainId("Sei"),
  chainToChainId("Sepolia"),
  chainToChainId("Snaxchain"),
  chainToChainId("Solana"),
  chainToChainId("Sui"),
  chainToChainId("Terra"),
  chainToChainId("Terra2"),
  chainToChainId("Unichain"),
  chainToChainId("Wormchain"),
  chainToChainId("Xlayer"),
  chainToChainId("Xpla"),
];

export const chainsSupportedByProtocol: Record<string, ChainId[]> = {
  [ALL_BRIDGE_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Celo"),
    chainToChainId("Ethereum"),
    chainToChainId("Optimism"),
    chainToChainId("Polygon"),
    chainToChainId("Solana"),
    // chainToChainId("Stellar"),
    // chainToChainId("Tron"),
  ],
  [C3_APP_ID]: [
    chainToChainId("Algorand"),
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Bsc"),
    chainToChainId("Ethereum"),
    chainToChainId("Polygon"),
    chainToChainId("Solana"),
  ],
  [CCTP_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Ethereum"),
    chainToChainId("Optimism"),
    chainToChainId("Polygon"),
    chainToChainId("Solana"),
  ],
  [CONNECT_APP_ID]: [
    chainToChainId("Aptos"),
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Celo"),
    chainToChainId("Ethereum"),
    chainToChainId("Evmos"),
    chainToChainId("Fantom"),
    chainToChainId("Injective"),
    chainToChainId("Klaytn"),
    chainToChainId("Kujira"),
    chainToChainId("Mantle"),
    chainToChainId("Moonbeam"),
    chainToChainId("Optimism"),
    chainToChainId("Osmosis"),
    chainToChainId("Polygon"),
    chainToChainId("Scroll"),
    chainToChainId("Solana"),
    chainToChainId("Sui"),
    chainToChainId("Xlayer"),
  ],
  [ETH_BRIDGE_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Ethereum"),
    chainToChainId("Optimism"),
    chainToChainId("Polygon"),
  ],
  [LIQUIDITY_LAYER_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Bsc"),
    chainToChainId("Solana"),
    chainToChainId("Base"),
  ],
  [GATEWAY_APP_ID]: [
    chainToChainId("Evmos"),
    chainToChainId("Injective"),
    chainToChainId("Kujira"),
    chainToChainId("Osmosis"),
    chainToChainId("Sei"),
  ],
  [GR_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Blast"),
    chainToChainId("Bsc"),
    chainToChainId("Celo"),
    chainToChainId("Ethereum"),
    chainToChainId("Fantom"),
    chainToChainId("Klaytn"),
    chainToChainId("Moonbeam"),
    chainToChainId("Optimism"),
    chainToChainId("Polygon"),
    chainToChainId("Scroll"),
    chainToChainId("Snaxchain"),
  ],
  [MAYAN_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Ethereum"),
    chainToChainId("Optimism"),
    chainToChainId("Polygon"),
    chainToChainId("Solana"),
  ],
  [NTT_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Ethereum"),
    chainToChainId("Fantom"),
    chainToChainId("Moonbeam"),
    chainToChainId("Optimism"),
    chainToChainId("Scroll"),
    chainToChainId("Solana"),
  ],
  [OMNISWAP_APP_ID]: [
    chainToChainId("Aptos"),
    chainToChainId("Avalanche"),
    chainToChainId("Bsc"),
    chainToChainId("Ethereum"),
    chainToChainId("Polygon"),
    chainToChainId("Solana"),
    chainToChainId("Sui"),
  ],
  [PORTAL_APP_ID]: [
    chainToChainId("Acala"),
    chainToChainId("Algorand"),
    chainToChainId("Aptos"),
    chainToChainId("Arbitrum"),
    chainToChainId("Aurora"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Celo"),
    chainToChainId("Ethereum"),
    chainToChainId("Evmos"),
    chainToChainId("Fantom"),
    chainToChainId("Injective"),
    chainToChainId("Karura"),
    chainToChainId("Klaytn"),
    chainToChainId("Kujira"),
    chainToChainId("Moonbeam"),
    chainToChainId("Optimism"),
    chainToChainId("Osmosis"),
    chainToChainId("Polygon"),
    chainToChainId("Scroll"),
    chainToChainId("Sei"),
    chainToChainId("Solana"),
    chainToChainId("Sui"),
    chainToChainId("Terra"),
    chainToChainId("Terra2"),
    chainToChainId("Xpla"),
  ],
  [SWAP_LAYER_APP_ID]: [chainToChainId("Ethereum")],
  [TBTC_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Ethereum"),
    chainToChainId("Optimism"),
    chainToChainId("Polygon"),
    chainToChainId("Solana"),
  ],
  [USDT_TRANSFER_APP_ID]: [
    chainToChainId("Arbitrum"),
    chainToChainId("Avalanche"),
    chainToChainId("Base"),
    chainToChainId("Bsc"),
    chainToChainId("Ethereum"),
    chainToChainId("Optimism"),
    chainToChainId("Polygon"),
  ],
};

export const protocolLinksByProtocol: Record<string, string> = {
  [ALL_BRIDGE_APP_ID]: ALLBRIDGE_URL,
  [C3_APP_ID]: C3_URL,
  [CCTP_APP_ID]: CCTP_URL,
  [CONNECT_APP_ID]: PORTAL_BRIDGE_URL,
  [ETH_BRIDGE_APP_ID]: PORTAL_BRIDGE_URL,
  [GATEWAY_APP_ID]: GATEWAY_URL,
  [GR_APP_ID]: GR_URL,
  [MAYAN_APP_ID]: MAYAN_URL,
  [NTT_APP_ID]: NTT_URL,
  [OMNISWAP_APP_ID]: OMNISWAP_URL,
  [PORTAL_APP_ID]: PORTAL_BRIDGE_URL,
  [PORTAL_NFT_APP_ID]: PORTAL_BRIDGE_URL,
  [TBTC_APP_ID]: TBTC_URL,
  [USDT_TRANSFER_APP_ID]: PORTAL_BRIDGE_URL,
};
